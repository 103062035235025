<template>
  <div class="customer-list">
    <pagoda-form-table-layout>
      <!-- 表单 -->
      <template slot="form">
        <el-form class="el-form-reset" inline>
          <el-form-item label="分组名称" prop="title">
            <el-input
              placeholder="请输入分组名称"
              v-model="formData.title"
            ></el-input>
          </el-form-item>
          <!-- 表单操作按钮 -->
          <div class="pagoda-button-group">
            <el-button @click="handleReset">重置</el-button>
            <el-button type="primary" @click="handleSubmit">查询</el-button>
          </div>
        </el-form>
        <el-button
          class="group-btn"
          type="primary"
          @click="handleAddMaterialGroup(1)"
        >
          添加
        </el-button>
      </template>
      <!-- 实现一屏展示时 一定要配置表格高度 -->
      <template slot="table" slot-scope="scope">
        <el-table
          ref="table"
          :data="tableData"
          :height="scope.height"
          v-loading="isTableLoading"
          element-loading-background="rgba(0, 0, 0, 0)"
        >
          <el-table-column label="序号" type="index"></el-table-column>
          <el-table-column
            prop="category_name"
            label="分组名称"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            label="创建时间"
          ></el-table-column>
          <el-table-column prop="username" label="操作人"></el-table-column>
          <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="
                  handleAddMaterialGroup(
                    2,
                    scope.row.id,
                    scope.row.category_name
                  )
                "
              >
                编辑
              </el-button>
              <el-button type="text" @click="handleDelete(scope.row.id)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 分页 -->
      <template slot="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :current-page="pageNumber"
          :total="totalDataNum"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </template>
    </pagoda-form-table-layout>
    <template>
      <!-- 弹窗 -->
      <el-dialog
        append-to-body
        :title="`${title}分组`"
        :visible="dialogVisible"
        width="width"
        :before-close="dialogBeforeClose"
        v-if="dialogVisible"
      >
        <el-form
          class="el-form-reset"
          ref="groupForm"
          :model="groupForm"
          v-show="this.title === '删除' ? false : true"
          :rules="rules"
          inline
        >
          <el-form-item label="分组名称" prop="groupName">
            <el-input
              placeholder="请输入分组名称"
              maxlength="20"
              v-model="groupForm.groupName"
            ></el-input>
          </el-form-item>
        </el-form>
        <div v-show="this.title === '删除'">
          <span class="el-icon-warning-outline"></span>
          确定删除这个分组吗？
        </div>
        <div slot="footer">
          <el-button @click="handleCancel">取 消</el-button>
          <el-button type="primary" @click="handleConfirm('groupForm')">
            确 定
          </el-button>
        </div>
      </el-dialog>
    </template>
  </div>
</template>
<script>
import http from '@/services/api/reach'
export default {
  data() {
    return {
      formData: {},
      tableData: [],
      totalDataNum: 0,
      customerNum: 0,
      pageSize: 10,
      pageNumber: 1,
      isTableLoading: false,
      title: '',
      dialogVisible: false,
      rules: {
        groupName: [{ required: true, message: '分组名称不能为空' }]
      },
      type: 1, // type=1添加，type=2编辑
      groupId: '',
      groupForm: {
        groupName: ''
      }
    }
  },
  methods: {
    handleSubmit() {
      const params = {
        type: 'material',
        group_name: this.formData.title
      }
      http
        .getMaterialGroup(params)
        .then((res) => {
          this.tableData = res.data || []
          this.totalDataNum = res.data.length || 0
        })
        .catch(() => {})
    },
    handleReset() {
      this.formData = {
        page: 1,
        page_size: 10
      }
      this.pageNumber = 1
      this.pageSize = 10
      this.handleSubmit()
    },
    // pageSize 改变时会触发
    handleSizeChange(val) {
      this.pageSize = val
      this.formData.page_size = val
      this.refreshTableData()
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.pageNumber = val
      this.formData.page = val
      this.handleSubmit()
    },
    // 刷新表格数据，重置回第一页
    refreshTableData() {
      this.pageNumber = 1
      this.formData.page = 1
      this.handleSubmit()
    },
    // 添加分组
    addGroup() {
      const params = {
        type: 'material',
        group_name: this.groupForm.groupName
      }
      http
        .addGroup(params)
        .then((res) => {
          this.handleSubmit()
          this.dialogVisible = false
        })
        .catch(() => {})
    },
    // 编辑分组
    editGroup() {
      const params = {
        type: 'material',
        group_name: this.groupForm.groupName,
        group_id: this.groupId
      }
      http
        .editGroup(params)
        .then((res) => {
          this.handleSubmit()
          this.dialogVisible = false
        })
        .catch(() => {})
    },
    // 操作分组管理
    handleAddMaterialGroup(type, id, name) {
      switch (type) {
        case 1:
          this.title = '添加'
          this.type = 1
          this.groupForm.groupName = ''
          this.dialogVisible = true
          break
        case 2:
          this.title = '编辑'
          this.type = 2
          this.groupId = id
          this.groupForm.groupName = name
          this.dialogVisible = true
          break
        default:
          break
      }
    },
    deleteGroup(id) {
      const params = { id }
      http
        .deleteGroup(params)
        .then((res) => {
          this.handleReset()
        })
        .catch(() => {})
    },
    handleDelete(id) {
      this.$confirm('确定删除这个分组吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteGroup(id)
        })
        .catch(() => {})
    },
    resetGroupForm() {
      this.dialogVisible = false
      this.groupId = ''
      this.$refs.groupForm.resetFields()
    },
    handleCancel() {
      this.resetGroupForm()
    },
    handleConfirm() {
      this.$refs.groupForm.validate((valid) => {
        if (valid) {
          if (this.type === 1) {
            this.addGroup()
          } else {
            this.editGroup()
          }
        }
      })
    },
    dialogBeforeClose() {
      this.resetGroupForm()
    }
  },
  created() {
    this.handleSubmit()
  },
  activated() {
    // 刷新列表数据
    this.refreshListData()
    this.$nextTick(() => {
      // 切换页面后表格偏移问题，对table进行重新布局
      this.$refs['table'].doLayout()
    })
  }
}
</script>

<style lang="less">
.customer-list {
  height: 100%;
  .el-date-editor--monthrange.el-input,
  .el-date-editor--monthrange.el-input__inner {
    width: 220px;
  }
}
.group-btn {
  margin-bottom: 10px;
}
</style>
